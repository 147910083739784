.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: #1a79c7;
  text-decoration: none;
}

body {
  font-family: monospace;
  font-weight: 100;
  font-size: 16px !important;
  overflow-x: hidden;
}

.custom-button {
  border-radius: 4px;
  padding: 5px;
  border: 2px solid #1a79c7;
  transition: all 200ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.custom-button:hover {
  border: 2px solid #f7d75c;
  color: #f7d75c;
}

.hili {
  color: #f7d75c;
}

.message {
  color: #a99342;
  background-color: #3b2f04;
  align-self: flex-start;
  padding: 20px 20px 8px 20px;
  width: 35vw;
}

@media (max-width: 798px) {
  .message {
    padding: 23px;
    width: calc(100% - 46px);
  }
}